const modalOverlay = document.querySelector(".video-modal--overlay");
const videoModalBtn = document.querySelector(".video-modal-btn");
const videoModal = document.querySelector(".video-modal");
const videoModalCloseBtn = document.querySelector(".video-modal--close");

if (videoModalBtn && videoModal) {
  videoModalBtn.addEventListener("click", () => {
    videoModal.classList.add("video-modal--active");
    document.body.style.overflowY = "hidden";
  });
}
if (modalOverlay) {
  modalOverlay.addEventListener("click", () => {
    videoModal.classList.remove("video-modal--active");
    document.body.style.overflowY = "";
  });
}

if (videoModalCloseBtn) {
  videoModalCloseBtn.addEventListener("click", () => {
    videoModal.classList.remove("video-modal--active");
    document.body.style.overflowY = "";
  });
}
