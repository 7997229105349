import Swiper from "swiper";
import { Pagination } from "swiper/modules";

const offerSlider = new Swiper(".offer-section__services__slider", {
  modules: [Pagination],
  direction: "horizontal",
  loop: false,
  slidesPerView: 1,
  spaceBetween: 20,
  pagination: {
    el: ".offer-section__services__pagination",
  },

  breakpoints: {
    600: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
});
