const references = document.querySelectorAll(".reference__content");

if (references) {
  references.forEach((reference) => {
    const readMore = reference.nextElementSibling;
    const readMoreText = readMore.querySelector("span");
    readMore.addEventListener("click", () => {
      if (readMore.classList.contains("reference__read-more--active")) {
        readMoreText.innerHTML = "więcej";
        reference.style.maxHeight = null;
        readMore.classList.remove("reference__read-more--active");
      } else {
        reference.style.maxHeight = "1000px";
        readMoreText.innerHTML = "mniej";
        readMore.classList.add("reference__read-more--active");
      }
    });
  });
}
