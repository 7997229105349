import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

const blogSlider = new Swiper(".blog-section__swiper", {
  modules: [Pagination, Navigation],
  direction: "horizontal",
  loop: false,
  slidesPerView: 1,
  spaceBetween: 20,
  pagination: {
    el: ".blog-section__swiper__pagination",
  },
  navigation: {
    nextEl: ".blog-section__swiper__next",
    prevEl: ".blog-section__swiper__prev",
  },

  breakpoints: {
    600: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
});
