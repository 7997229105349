const numbersWrapper = document.querySelector(".experience-section__numbers");
const numbers = document.querySelectorAll(".xxl-text .number");

function animateValue(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
const elementIsVisibleInViewport = (el, partiallyVisible = true) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
        (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

if (numbers) {
  if (elementIsVisibleInViewport(numbersWrapper)) {
    numbers.forEach((number) => {
      if (number.dataset.number) {
        animateValue(number, 0, number.dataset.number, 5000);
      }
    });
  }
  let numbersVisible = false;
  window.addEventListener("scroll", () => {
    if (!numbersVisible) {
      numbersVisible = elementIsVisibleInViewport(numbersWrapper);
      numbers.forEach((number) => {
        if (number.dataset.number) {
          if (numbersVisible)
            animateValue(number, 0, number.dataset.number, 5000);
        }
      });
    }
  });
}
