const faqItems = document.querySelectorAll(".faq-item");

if (faqItems) {
  faqItems.forEach((faqItem) => {
    const trigger = faqItem.querySelector(".faq-item__question");
    trigger.addEventListener("click", () => {
      faqItem.classList.toggle("faq-item--active");
    });
  });
}
